import { state, props } from 'cerebral'
import { when, wait } from 'cerebral/factories'
import { getOnlineStatusForAmplitude, getStreamStatusForAmplitude, send } from '../../socket/actions'
import openShop from '../../app/signals/openShop'
import panelsOnPop from '../../app/signals/panelsOnPop'
import { getUser } from '@/utils/userLoader'
import showSnackbar from '../../app/signals/showSnackbar'
import { sendAmplitudeEvent } from '@/utils/GAEvent'
import askRateApp from '@/utils/askRateApp'
import { getUserAgeRating } from '../../../utils/ageRating/ageRatingUtil'
import { getCoreUrl } from '../../../utils/api/apiURLUtil'
import { getSessionId, isMe } from '../../../utils'
import { getGiftCost, isGiftDiscount, SUPER_LIKE_GIFT } from '../../../utils/giftUtil'
import { closeCurrentPanel } from '../../../components/Panels/createPanelUtil'
import { subscriptionButtonClick } from '../../../utils/subscriptionButtonClick'
import { getState } from '../../../utils/StoreManager'
import { cancelDislike } from '../../../utils/post/dislikePost'
import { scrollToNextPost } from '../../../utils/navigation/datingScrollUtil'
import { setAnimateSuperLike } from '../../../components/Levels/animatedLike'

export default [
  when(state`auth.coins`, state`intl.settings.giftsNew`, props`giftId`,
            (coins, gifts, giftId) => coins >= getGiftCost(giftId)), {
    true: [
      ({ props }) => ({
        method: 'sendGift',
        params: { giftId: props.giftId, toUid: props.to }
      }),
      // send,
      async ({ props }) => {
        closeCurrentPanel()
        if (props.giftId === SUPER_LIKE_GIFT){
          subscriptionButtonClick(props.to, false)
          const postId = getState('currentPostId')
          cancelDislike(postId)
          scrollToNextPost(postId)
          setAnimateSuperLike()
        }
        const response = await fetch(getCoreUrl('/api/v1/gifts'), {
          method: 'POST',
          headers: {
            'ft-sid': getSessionId()
          },
          body: new URLSearchParams({
            to_uid: props.to,
            gift_id: props.giftId,
          })
        })
      },
      ({ store, get, props }) => ({ 
        text: get(state`intl.content.log_send_present`)
              .replace('%gift%', get(state`intl.content.${props.giftId}`) || '')
              .replace('%to%', getUser(props.to).name), 
        type: 'success',
        additionalInfo: null
      }),
      showSnackbar,
      ({ get, path }) => {
        const panels = get(state`app.panels`) || []
        if (panels.length < 1) {
          return path.doNothing()
        }
        if (panels[panels.length - 1].type === 'dialog') {
          return path.doNothing()
        }
        return path.closePanels()
      }, {
        doNothing: [],
        closePanels: [
          panelsOnPop,
          wait(350),
          panelsOnPop,
        ]
      },
      ({ store, get, props }) => {
        const cost = getGiftCost(props.giftId)
        sendAmplitudeEvent('gifts', {
          counterparty_id: props.to,
          gift_type: 'gift',
          gift_id: props.giftId,
          selfGift: isMe(props.to),
          discount: isGiftDiscount(),
          online: getOnlineStatusForAmplitude(props.to),
          stream: getStreamStatusForAmplitude(props.to),
          age_rating: getUserAgeRating(props.to),
          sent_coins: cost
        })
      },
      ()=>{
        askRateApp('gift')
      }
    ],
    false: [
      ({ store, get, props }) => {
        const coins = +get(state`auth.coins`)
        const message = get(state`intl.content.need_more_coins`)
        return {
          message: message.replace(/\{0\}/gi, getGiftCost(props.giftId) - coins),
          reason: 'buy-gift'
        }
      },
      openShop,
    ]
  }
]
