import { getState } from './StoreManager'

export function imOnHomeRoute() {
  return getState('app.route') === 'home'
}

export function imInRoom() {
  return Boolean(getState('app.roomId'))
}

export function isCDNDisable(){
  // return true
  return getState('cdnDisable')
}

export function getStatusBarH(){
  return getState('statusBarH') || 0
}
