import { callSequence, getSequence } from './StoreManager'
import { getLocaleValue } from './localeUil'
import { getUser } from './userLoader'
import { getMyUid } from './my'
import { showSnackbarNew } from '../modules/app/factories'
import { createGiftSetsPanel } from '../components/Panels/createPanelUtil'
import { SPAMMER_UID } from '../modules/chat/actions'
import { getUserName } from './userUtil'
import { getGiftCost } from './giftUtil'
import { getCost } from './stringUtils'
// import { GET_GEO_SNACKBAR } from '../components/Snackbar/index.js'

export const SET_PROFILE_SNACKBAR = 'profile'
export const VIDEO_GIFT = 'video_gift'
export const GET_GEO_SNACKBAR = 'getGeo'
export const ERROR_SNACKBAR = 'error'
export const CONFIRM_SNACKBAR = 'dellConfirm'
export const GIFT_SNACKBAR = 'gift'

//уведомление о ошибке
export function showErrorSnackbar(key = 'support_problem_errorApp') {
  const showSnackbar = getSequence('app.showSnackbar')
  showSnackbar({
    text: getLocaleValue(key),
    type: 'error'
  })
}

//уведомление о успехе
export function showSuccessSnackbar(key) {
  const showSnackbar = getSequence('app.showSnackbar')
  showSnackbar({
    text: getLocaleValue(key || 'success'),
    type: 'success'
  })
}

export function showTrashSnackbar(key = 'deleted') {
  const showSnackbar = getSequence('app.showSnackbar')
  showSnackbar({
    text: getLocaleValue(key || 'deleted'),
    type: 'trash'
  })
}

//запрос на пуши
export function askPushSnackbar(uid) {
  const user = getUser(uid)
  const showSnackbar = getSequence('app.showSnackbar')
  showSnackbar({
    text: getLocaleValue('enable_notifications_popup', { '%userName%': user ? user.name : 'User' }),
    type: 'push'
  })
}

const promotedGifts = ['present_exclusive_love_teddy', 'roses']
export function askGiftSnackbar(toUid) {
  const giftId = promotedGifts[Math.floor(promotedGifts.length*Math.random())] //'present_exclusive_love_teddy'
  showSnackbarNew(getLocaleValue('gift_for_answer_notification', { '%userName%': getUserName(toUid) }),
    5000,
    'gift',
    {
      buttonKey: getCost(getGiftCost(giftId)),
      giftId,
      onClick: ()=> { callSequence('room.sendGift', { giftId, to:toUid })}
  })
}

//напоминание о заполнении профиля
export function profileFillSnackbar() {
  const showSnackbar = getSequence('app.showSnackbar')
  showSnackbar({
    text: getLocaleValue('set_profile_notification'),
    type: SET_PROFILE_SNACKBAR
  })
}

//напоминание о GEO разрешении
export function geoRequestSnackbar() {
  const showSnackbar = getSequence('app.showSnackbar')
  showSnackbar({
    text: getLocaleValue('allow_geo_notification'),
    type: GET_GEO_SNACKBAR
  })
}

export function upRoomMessage(data){
  callSequence('room.addPopup', data)
}

//снекбар с подтверждением
export function confirmSnackbar(mainTextKey, buttonKey, onClick) {
  showSnackbarNew(getLocaleValue(mainTextKey), 5000, CONFIRM_SNACKBAR,{
    buttonKey,
    onClick
  })
}

//allow_geo_notification
//geo_search_improved
