import { getState, setStoreWrapper } from '../StoreManager'

export function setAvatarChanged(){
  setStoreWrapper('avatarChanged', true)
}

export function isAvatarChanged(){
  return getState('avatarChanged')
}

export function setAvatarUpdated(){
  setStoreWrapper('avatarChanged', false)
}
