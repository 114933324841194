import { GOOD_FOR_12, GOOD_FOR_14, GOOD_FOR_16, GOOD_FOR_18, AFTER_PRIVATE_DELAY } from '@/modules/buffs'
import controller from '@/controller'
import { isBuffActive } from '@/utils/buffUtils'
import { isUserBannedMyCountry } from '@/utils/userLoader'
import is from 'is_js'
import getStreamMetadata from '@/utils/getStreamMetadata'
import { isTWA } from '@/utils/twa'
import { getUser } from '@/utils/userLoader'
import { checkMySubscription, LEVEL_VIP, LIVE_PERMISSION } from '../../utils/subscriptionUtil'
import { getUserFeed, isVIPFilterSelected } from '../posts/signals/getPosts'
import {
  posterVisibleStream,
  isWaitForCall,
  isStreamNSFW,
  roomVisibleStreamWithNSFW, isProfileVisible
} from '../../utils/ageRating/ageRatingUtil'
import { streamComplaintCheck } from '../../utils/streamUtil'
import { state } from 'cerebral'
import { isOnlySubs } from '../filters/actions/feedUtil'
import { getState } from '../../utils/StoreManager'

export const isVipStream = uid => {

  const cost = controller.getState(`app.paidRooms.${uid}`)
  if (cost === 0 || cost > 0) { return true }

  const user = getUser(uid)
  if (user && user.subscriptionSettings && 
      user.subscriptionSettings[LIVE_PERMISSION] === LEVEL_VIP) {
    return true
  }

  return false
}

export const getAgeRatingByUid = uid => {
  let broadcastAgeRating = 99
  
  if (isBuffActive(uid, AFTER_PRIVATE_DELAY)) {
    return 98
  }
  if (isBuffActive(uid, GOOD_FOR_12)) {
    broadcastAgeRating = 12
  }
  if (isBuffActive(uid, GOOD_FOR_14)) {
    broadcastAgeRating = 14
  }
  if (isBuffActive(uid, GOOD_FOR_16)) {
    broadcastAgeRating = 16
  }
  if (isBuffActive(uid, GOOD_FOR_18)) {
    broadcastAgeRating = 18
  }

  return broadcastAgeRating
}

export const getBroadcast = uid => {
   return controller.getState('posters.raw')[uid]
}

let teamMembers = null
export const isTeamMember = uid => {
  return null
  // const hosts = controller.getState('intl.domainsConfig')
  // if (!hosts) { return null }
  // if (!teamMembers) {
  //   teamMembers = {}
  //   for (const host in hosts) {
  //     const hostData = hosts[host]
  //     if (!hostData.team) { continue }
  //     for (let i = 0; i < hostData.team.length; i++) {
  //       const memberUid = hostData.team[i]
  //       if (!teamMembers[memberUid]) {
  //         teamMembers[memberUid] = []
  //       }
  //       teamMembers[memberUid].push(hostData.subject)
  //     }
  //   }
  // }
  // return teamMembers[uid]
}

const iOSversion = () => {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/)
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)]
  }
}


const codecAvailableInIos = uid => {
  return true
  // const metadata = getStreamMetadata(uid)
  // if (metadata && metadata.codec && typeof metadata.codec === 'string') {
  //   if (metadata.codec.toUpperCase() === 'H264') {
  //     return true
  //   }
  //   const ver = iOSversion() || [0]
  //
  //   if (ver[0] >= 14) {
  //     return true
  //   }
  //   return false
  // }
  // return true
}

export const showInPosters = (brData, filters) => {
  if (!brData || !brData.uid) { return false }

  // const subs = controller.getState('auth.subs') || {}
  // const userFeed = isOnlySubs()

  // const vipFilterSelected = isVIPFilterSelected(filters || controller.getState('posts.filters'))

  // if (userFeed) {
  //   if (!subs[brData.uid]) {
  //     return false
  //   }
  //   // if (!isTWA()) { return true }
  // }

  // if (vipFilterSelected && !isVipStream(brData.uid)) {
  //   return false
  // }
  
  const brAgeRating = getAgeRatingByUid(brData.uid)
  if (brAgeRating > 18) { 
    return false 
  }
  // if (controller.getState(`posters.privates.${brData.uid}`)) {
  //   return false
  // }

  // if (is.ios() && !codecAvailableInIos(brData.uid)) {
  //   return false
  // }

  if (isUserBannedMyCountry(brData.uid)) {//проверка на бан cтраны
    return false
  }

  //проверка на бан стрима мною
  if (streamComplaintCheck(brData.uid)){
    return false
  }

  //стрим в обычном или NSWF конфиге
  if (!posterVisibleStream(brData.uid)) {
    //оставим пока "жду звонка"
    return isWaitForCall(brData.uid) && isProfileVisible(brData.uid)
  }

  /** отключим учет стримов команды
  const team = isTeamMember(brData.uid)
  if (team) {
    return team.indexOf(controller.getState('app.subject')) !== -1
  }
   */

  return true
}

export const showInRoom = brData => {
  if (!brData || !brData.uid) { return false }
  const isInRoom = controller.getState('app.roomId') === brData.uid
  if (streamComplaintCheck(brData.uid)){
    return false
  }

  if (brData.uid === controller.getState('auth.uid') ||
      brData.uid === controller.getState('app.isInPrivate')) {
    return true
  }

  if (isBuffActive(brData.uid, AFTER_PRIVATE_DELAY)) {
    if (isInRoom)console.log('@@@ block broadcast - author has rest buff')
    return false
  }

  if (isUserBannedMyCountry(brData.uid)) {//проверка на бан cтраны
    if (isInRoom)console.log('@@@ block broadcast - country ban')
    return false
  }

  if (controller.getState(`posters.privates.${brData.uid}`)) {
    if (isInRoom)console.log('@@@ block broadcast - in private')
    return false
  }
  
  if (is.ios() && !codecAvailableInIos(brData.uid)) {
    return false
  }
  
  if (isTWA()) {
    const brAgeRating = getAgeRatingByUid(brData.uid)
    if (brAgeRating > 18) {
      if (isInRoom)console.log('@@@ block broadcast - invalid rating', brAgeRating)
      return false 
    }
  
    // let liveRatings = controller.getState('intl.appSourceConfig.liveRatings') || []
    const allowNSFW = controller.getState('auth.stuff.allowNsfw')
    //
    // if (allowNSFW) {
    //   const liveRatingsNSFW = controller.getState('intl.appSourceConfig.liveRatingsNSFW') || []
    //   liveRatings = [...liveRatings, ...liveRatingsNSFW]
    //   const subs = controller.getState('auth.subs')
    //   if (subs[brData.uid]) {
    //     liveRatings = [...liveRatings, 12, 14]
    //   }
    // }
    if (roomVisibleStreamWithNSFW(brData.uid)){
      return true
    }
  
    if (isWaitForCall(brData.uid)) {
      if (isInRoom)console.log('@@@ block broadcast - rating', brAgeRating, 'allow NSFW', allowNSFW)
      return false//wait for call broadcasts
    }
  } else {
    //если я подписан то любой возастной рейтинг лайва
    // if (checkMySubscription(brData.uid)){
    //   return true
    // }
  }

  if (!roomVisibleStreamWithNSFW(brData.uid)){
    console.log('@@@ WEB roomVisibleStreamWithNSFW ', brData.uid)
    return false
  }

  return true
}

export default showInRoom
